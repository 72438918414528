import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// @mui
import { Box, Drawer, Link, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// hooks
import { useAccessControlContext } from '@src/context/AccessControl';
import useResponsive from '@src/hooks/useResponsive';

// components
import { Dropdown } from '@src/components/dropdown';
import Logo from '@src/components/logo';
import NavSection from '@src/components/nav-section';
import Scrollbar from '@src/components/scrollbar';
// import { usePartnerDropdownContext } from '@src/context/PartnerDropdown';

import { useAuthService } from '@src/store/hooks';
import { FullAccess } from '@src/utils/drawer-list';
// import { PARTNER_OPTIONS } from '@src/utils/mockdata/menu-options';

import UpdateUserDetails from '@src/components/update-user-details';
import { ControlPanelAccess, DashboardAccess, GameAccess, MarketingToolsAccess, MostPlayedAccess, PlatformToolsAccess, PurchasesAccess, RedeemTransactionsAccess, RevenueShareAccess, SettingsAccess, UsersAccess } from '@src/lib/types/access-control';
// import { PartnerDropdownContextStateType } from '@src/lib/types/partner-provider';
import { pluck } from '@src/utils/pluck';
import AccountMenu from './AccountMenu';
import Config from '@src/utils/api/config';
import { PARTNER_OPTIONS } from '@src/utils/mockdata/menu-options';
import { PartnerDropdownContextStateType } from '@src/lib/types/partner-provider';
import { usePartnerDropdownContext } from '@src/context/PartnerDropdown';

const { isFortune8, isPalmsPlay } = Config;

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  padding: theme.spacing(1.5, 1.6),
  paddingRight: "5px",
  border: "1px solid",
  borderColor: theme.palette.grey[300],
  borderRadius: Number(theme.shape.borderRadius),
}));

type Route = {
  title: string;
  path: string;
  icon?: JSX.Element;
  isSpacer?: boolean;
  children?: Route[];
}

const Nav = ({ openNav, onCloseNav }: any) => {
  const [open, setOpen] = useState<any>(null);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] = useState<boolean>(false);

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { currentUser, onLogout } = useAuthService();
  const { state, handleSelectPartner } = usePartnerDropdownContext();
  const { state: accessControlState } = useAccessControlContext();


  const filteredRoutes = useMemo(() => {
    if (!accessControlState) return FullAccess;

    const dashboardAccess: DashboardAccess = pluck(accessControlState, 'access.dashboard');
    const gamesAccess: GameAccess = pluck(accessControlState, 'access.games');
    const usersAccess: UsersAccess = pluck(accessControlState, 'access.users');
    const mostPlayedAccess: MostPlayedAccess = pluck(accessControlState, 'access.mostPlayed');
    const purchasesAccess: PurchasesAccess = pluck(accessControlState, 'access.purchases');
    const platformToolsAccess: PlatformToolsAccess = pluck(accessControlState, 'access.platformTools');
    const marketingToolsAccess: MarketingToolsAccess = pluck(accessControlState, 'access.marketingTools');
    const revenueShareAccess: RevenueShareAccess = pluck(accessControlState, 'access.revenueShare');
    const redeemTransactionsAccess: RedeemTransactionsAccess = pluck(accessControlState, 'access.redeemTransactions');
    const controlPanelAccess: ControlPanelAccess = pluck(accessControlState, 'access.controlPanel');
    const settingsAccess: SettingsAccess = pluck(accessControlState, 'access.settings');

    const hasAccessToAllWidgets = dashboardAccess && Object.values(dashboardAccess).filter(p => p === true).length > 0;
    const hasControlPanelAccess = isPalmsPlay || controlPanelAccess?.allowViewTeam || controlPanelAccess?.allowViewPartnersList || controlPanelAccess?.allowViewLicenseKeys || redeemTransactionsAccess?.allowView;
    const hasSettingsAccess = settingsAccess?.allowViewDetails || settingsAccess?.allowUpdateBusinessDetails || settingsAccess?.allowViewTermsAndConditions || settingsAccess?.allowViewPortalGuide;

    const routesArr: Route[] = [];

    FullAccess.forEach((route) => {
      const tempRoute = { ...route };

      if (
        (route.title === 'dashboard' && hasAccessToAllWidgets) ||
        (route.title === 'games' && gamesAccess?.allowViewList) ||
        (route.title === 'users' && usersAccess?.allowViewList) ||
        (route.title === 'most played' && mostPlayedAccess?.allowViewList) ||
        (route.title === 'purchases' && purchasesAccess?.allowViewList) ||
        (route.title === 'revenue program' && revenueShareAccess?.allowView && isFortune8) ||
        (route.title === 'logout' && true)
      ) {
        routesArr.push(tempRoute);
      }

      if ((route.title === 'platform tools')) {
        const tempRouteItem = {
          ...route,
          children: route.children?.filter((routeChild) => {
            return (routeChild.title === 'overview' && platformToolsAccess?.allowQuickViewData) ||
              (routeChild.title === 'in mail portal' && platformToolsAccess?.allowMails) ||
              (routeChild.title === 'missions' && platformToolsAccess?.allowMissions);
          }),
        };

        routesArr.push(tempRouteItem);
      }

      if ((route.title === 'marketing tools' && isFortune8)) {
        const tempRouteItem = {
          ...route,
          children: route.children?.filter((routeChild) => {
            return (routeChild.title === 'code generator' && (isFortune8 && marketingToolsAccess?.allowCodeGenerator));
          }),
        };

        routesArr.push(tempRouteItem);
      }

      if ((route.title === 'control panel' && hasControlPanelAccess)) {
        const tempRouteItem = {
          ...route,
          children: route.children?.filter((routeChild) => {
            return (routeChild.title === 'redeem transactions' && (isPalmsPlay || redeemTransactionsAccess?.allowView)) ||
              (routeChild.title === 'manage team' && controlPanelAccess?.allowViewTeam) ||
              (routeChild.title === 'partners' && controlPanelAccess?.allowViewPartnersList && isFortune8) ||
              (routeChild.title === 'licenses' && controlPanelAccess?.allowViewLicenseKeys && isFortune8);
          }),
        };

        routesArr.push(tempRouteItem);
      }

      if (route.title === 'settings' && hasSettingsAccess) {
        const tempRouteItem = {
          ...route,
          children: route.children?.filter((routeChild) => {
            return (routeChild.title === 'update user details' && true) ||
              (routeChild.title === 'company profile' && settingsAccess?.allowViewDetails) ||
              (routeChild.title === 'terms & conditions' && settingsAccess?.allowViewTermsAndConditions && isFortune8) ||
              (routeChild.title === 'portal guide' && settingsAccess?.allowViewPortalGuide);
          }),
        };

        routesArr.push(tempRouteItem);
      }
    });

    return routesArr;
  }, [accessControlState]);

  const handleCloseMenu = () => setOpen(null);

  const handleToggleUserDialog = (value?: boolean | undefined) => {
    if (typeof value === 'boolean') setOpenUpdateUserDialog(value);
    if (!value) setOpenUpdateUserDialog((prev) => !prev);
  };

  const handleLogout = () => {
    setOpen(null);
    onLogout();
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 3, pt: 4, pb: 2, display: 'inline-flex' }}>
        <Logo />
      </Box>
      <Box sx={{ mx: 3 }}>
        <StyledAccount>
          <Box>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {currentUser?.displayName}
            </Typography>
          </Box>
        </StyledAccount>
        {isFortune8 && <Box sx={{ py: 2, width: '100%' }}>
          <Dropdown
            placeholder='Select a Partner'
            items={PARTNER_OPTIONS}
            defaultSelected={state.selectedPartnerId as unknown as PartnerDropdownContextStateType}
            onSelect={handleSelectPartner}
          />
        </Box>}
      </Box>
      <NavSection data={filteredRoutes} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'white',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          anchor='right'
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
      <AccountMenu
        open={open}
        handleClose={handleCloseMenu}
        handleOpenUserDialog={() => handleToggleUserDialog(true)}
        handleLogout={handleLogout}
      />
      <UpdateUserDetails
        open={openUpdateUserDialog}
        handleClose={() => handleToggleUserDialog()}
      />
    </Box>
  );
}

export default React.memo(Nav);
